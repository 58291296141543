import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";
import GameBoard from "./GameBoard";
import SecretBoard from "./SecretBoard";
import firebase from "firebase/app";
import { FirebaseAppProvider } from "reactfire";
import LoadingIndicator from "react-loading-indicator";

import "./App.css";

export default function App() {
  return (
    <div className="App">
      <FirebaseAppProvider firebaseApp={firebase.app()} suspense={true}>
        <Suspense fallback={<LoadingIndicator />}>
          <Router>
            <Switch>
              <Route path="/game/:id" component={GameBoard} />
              <Route path="/player/:id" component={SecretBoard} />
              <Route path="/" component={Home} />
            </Switch>
          </Router>
        </Suspense>
      </FirebaseAppProvider>
    </div>
  );
}
