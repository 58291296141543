import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/database";

const config = {
  apiKey: "AIzaSyBi3Dz12b-oFzvYQd3G3qTdwGuk_IAvKWU",
  authDomain: "wordgame-e0c2c.firebaseapp.com",
  databaseURL: "https://wordgame-e0c2c.firebaseio.com",
  projectId: "wordgame-e0c2c",
  storageBucket: "wordgame-e0c2c.appspot.com",
  messagingSenderId: "690266022313",
};

window.firebase_api =
  "https://us-central1-wordgame-e0c2c.cloudfunctions.net/api";
window.web_base = "https://wordgame-e0c2c.firebaseapp.com";

firebase.initializeApp(config);

if (process.env.NODE_ENV === "development") {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 9000);
}
