import React, { useState, useEffect, useCallback } from "react";
import range from "lodash/range";
import Modal from "react-modal";
import QRCode from "qrcode.react";
import addSeconds from "date-fns/addSeconds";
import { Button } from "react-bootstrap";
import WordCard from "./WordCard";
import { useParams, useHistory } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import useInterval from "./useInterval";

const stateToBackgroundColorMap = {
  0: "blue",
  1: "red",
  "-1": "grey",
  "-2": "black",
};

const stateToColorMap = {
  0: "rgba(255, 255, 255, 0.40)",
  1: "rgba(255, 255, 255, 0.40)",
  "-1": "rgba(255, 255, 255, 0.40)",
  "-2": "white",
};

function coveredPiecesForTeam(team, locations, state) {
  const covered = locations.filter((owner, index) => {
    return team === owner && !state[index];
  });

  return covered.length;
}

function WordMatrix({
  game,
  onClick,
  waitingForNextTurn,
  lastSelectedCardAnimation,
  lastSelectedWordIndex,
}) {
  if (!game) {
    return <div />;
  }

  const { words, locations, current, state } = game;
  let currentColor = current === 0 ? "blue" : "red";
  let backgroundColor = "rgba(255, 255, 255, 1.0)";

  if (waitingForNextTurn || game.winner === 0 || game.winner === 1) {
    currentColor = "white";
  }

  if (currentColor === "blue") {
    backgroundColor = "rgba(0, 0, 255, 0.5)";
  } else if (currentColor === "red") {
    backgroundColor = "rgba(255, 0, 0, 0.5)";
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        background: backgroundColor,
        padding: "5px",
      }}
    >
      {range(5).map((row) => {
        return (
          <div
            key={`row_${row}`}
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
            }}
          >
            {range(5).map((column) => {
              const index = row * 5 + column;

              return (
                <WordCard
                  key={index}
                  animation={lastSelectedCardAnimation}
                  selected={lastSelectedWordIndex === index}
                  uncovered={state[index]}
                  bgColor={stateToBackgroundColorMap[locations[index]]}
                  color={stateToColorMap[locations[index]]}
                  onClick={state[index] ? null : () => onClick(index)}
                >
                  {words[index]}
                </WordCard>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function TimeRemaining({ secondsRemaining, onClick }) {
  if (!secondsRemaining) {
    return <div />;
  }

  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining - minutes * 60;
  const formatted = `${minutes}:${("0" + seconds).slice(-2)}`;
  return (
    <div onClick={onClick} style={{ padding: "5px", marginRight: "10px" }}>
      <i className="fa fa-clock-o" />
      &nbsp;
      {formatted} left
    </div>
  );
}

function SecretQRCode({ playerUrl }) {
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <QRCode value={playerUrl} size={400} />
      </div>
      <div style={{}}>
        Scan this with your phone to open the hint-giver page.{" "}
        <a href={playerUrl}>Link</a>
      </div>
    </>
  );
}

export default function GameBoard() {
  const { id: gameId } = useParams();
  const db = useFirestore();
  const { data: game } = useFirestoreDocData(
    db.collection("games").doc(gameId)
  );
  const history = useHistory();

  const [roundEndTime, setRoundEndTime] = useState();
  const [secondsRemaining, setSecondsRemaining] = useState(60 * 4);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [startModalOpen, setStartModalOpen] = useState(true);
  const [waitingForNextTurn, setWaitingForNextTurn] = useState(false);
  const [lastSelectedWordIndex, setLastSelectedWordIndex] = useState();
  const [nextTurnReason, setNextTurnReason] = useState();
  const [lastSelectedCardAnimation, setLastSelectedCardAnimation] = useState();

  const playerUrl = `${window.location.origin}/player/${gameId}`;

  useEffect(() => {
    document.body.classList.toggle("noscroll", true);
    return () => document.body.classList.toggle("noscroll", false);
  }, []);

  useInterval(() => {
    let newSecondsRemaining = null;
    if (roundEndTime) {
      newSecondsRemaining = Math.floor((roundEndTime - new Date()) / 1000);

      if (newSecondsRemaining <= 0) {
        setSecondsRemaining(0);
        setRoundEndTime(null);
        setWaitingForNextTurn(true);
      } else {
        setSecondsRemaining(newSecondsRemaining);
      }
    } else {
      setSecondsRemaining(null);
    }
  }, 500);

  function openQRModal() {
    setQrCodeModalOpen(true);
  }

  function closeQRModal() {
    setQrCodeModalOpen(false);
  }

  function closeStartModal() {
    setStartModalOpen(false);
    setRoundEndTime(addSeconds(new Date(), 4 * 60));
  }

  function startNextTurn() {
    db.collection("games")
      .doc(gameId)
      .update({ current: game.current === 0 ? 1 : 0 });

    setWaitingForNextTurn(false);
    setNextTurnReason(null);
    setRoundEndTime(addSeconds(new Date(), 2 * 60));
  }

  function onStartNewGame() {
    history.push("/");
  }

  function closeNextTurnModal() {
    startNextTurn();
  }

  function clickEndTurn() {
    startNextTurn();
  }

  async function clickWord(index) {
    if (waitingForNextTurn || game.winner === 0 || game.winner === 1) {
      return;
    }

    setLastSelectedWordIndex(index);

    // update in the database...
    const docRef = db.collection("games").doc(gameId);
    // We're okay with this happening asynchronously.
    docRef.update({
      [`state.${index}`]: 1,
    });

    // decide if turn or game is over!
    const tileOwner = game.locations[index];

    const newGameState = {
      ...game.state,
      [index]: 1,
    };

    let winner = null;
    if (tileOwner === -2) {
      // game over! hit the nuke
      winner = game.current === 0 ? 1 : 0;
      setLastSelectedCardAnimation("wobble");
    } else if (coveredPiecesForTeam(0, game.locations, newGameState) === 0) {
      setLastSelectedCardAnimation("pulse");
      winner = 0;
    } else if (coveredPiecesForTeam(1, game.locations, newGameState) === 0) {
      setLastSelectedCardAnimation("pulse");
      winner = 1;
    } else if (tileOwner !== game.current) {
      setLastSelectedCardAnimation("shake");
      setWaitingForNextTurn(true);
      setNextTurnReason("Uncovered someone else's card!");
    } else {
      setLastSelectedCardAnimation("pulse");
    }

    if (winner === 0 || winner === 1) {
      setRoundEndTime(null);
      await docRef.update({ winner, roundEndTime: null });
    }
  }

  function renderBar() {
    if (!game) {
      return <div />;
    }

    if (game.winner === 0 || game.winner === 1) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "50px",
            padding: "5px",
            fontSize: "1.2em",
          }}
        >
          <div style={{ padding: "5px", position: "absolute", right: "5px" }}>
            <Button onClick={onStartNewGame}>New Game</Button>
          </div>

          <div style={{ padding: "5px", marginRight: "10px" }}>
            {game.winner === 0 ? (
              <span style={{ color: "blue" }}>Blue Wins!</span>
            ) : (
              <span style={{ color: "red" }}>Red Wins!</span>
            )}
          </div>
        </div>
      );
    }

    if (waitingForNextTurn) {
      const currentColor = game.current === 0 ? "blue" : "red";
      const nextColor = game.current === 0 ? "red" : "blue";
      return (
        <div>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "50px",
              padding: "5px",
              fontSize: "1.2em",
            }}
          >
            <div
              style={{
                padding: "5px",
                bottom: "6px",
                position: "absolute",
                right: "5px",
              }}
            >
              <Button onClick={closeNextTurnModal}>
                Start{" "}
                <span style={{ color: nextColor }}>
                  {game && game.current === 0 ? "Red" : "Blue"}
                </span>{" "}
                turn
              </Button>
            </div>

            <p
              style={{
                padding: "10px",
                paddingBottom: "16px",
                textAlign: "center",
              }}
            >
              <span style={{ color: currentColor }}>
                {game && game.current === 0 ? "Blue" : "Red"}
              </span>{" "}
              turn is over!
            </p>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "50px",
          padding: "5px",
          fontSize: "1.2em",
        }}
      >
        <div
          style={{ padding: "5px", marginRight: "10px" }}
          onClick={openQRModal}
        >
          <i className="fa fa-qrcode" />
          &nbsp;Load Hinter View
        </div>

        <div
          style={{ display: "flex", flex: "1", justifyContent: "space-evenly" }}
        >
          <div style={{ padding: "5px", marginRight: "10px" }}>
            <span style={{ color: "blue" }}>
              Blue {coveredPiecesForTeam(0, game.locations, game.state)}
            </span>{" "}
            <span style={{ color: "#eee" }}>|</span>{" "}
            <span style={{ color: "red" }}>
              Red {coveredPiecesForTeam(1, game.locations, game.state)}
            </span>
          </div>

          <div style={{ padding: "5px", marginRight: "10px" }}>
            {game.current === 0 ? (
              <span style={{ color: "blue" }}>Blue Turn</span>
            ) : (
              <span style={{ color: "red" }}>Red Turn</span>
            )}
          </div>

          <TimeRemaining
            secondsRemaining={secondsRemaining}
            onClick={() => setRoundEndTime(addSeconds(new Date(), 2 * 60))}
          />
        </div>

        <div style={{ padding: "5px", marginRight: "10px" }}>
          <Button onClick={clickEndTurn}>End Turn</Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="GameBoard"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <WordMatrix
        game={game}
        waitingForNextTurn={waitingForNextTurn}
        lastSelectedCardAnimation={lastSelectedCardAnimation}
        lastSelectedWordIndex={lastSelectedWordIndex}
        onClick={clickWord}
      />

      {waitingForNextTurn ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 50,
            left: 0,
            right: 0,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        />
      ) : null}

      {renderBar()}

      <Modal isOpen={qrCodeModalOpen} ariaHideApp={false}>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <SecretQRCode playerUrl={playerUrl} />
          <div>
            <Button onClick={closeQRModal}>Done</Button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={startModalOpen} ariaHideApp={false}>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <SecretQRCode playerUrl={playerUrl} />

          <p>Ready to start?</p>
          <Button onClick={closeStartModal}>Start!</Button>
        </div>
      </Modal>
    </div>
  );
}
