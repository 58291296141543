import React from "react";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";

export default function SecretBoard() {
  const { id: gameId } = useParams();
  const db = useFirestore();
  const { data: game } = useFirestoreDocData(
    db.collection("games").doc(gameId)
  );

  function renderTeamWords(words) {
    return words.map((w) => (
      <div
        key={w.index}
        style={{
          textDecoration: w.state === 1 ? "line-through" : "none",
          padding: "5px",
        }}
      >
        {w.word}
      </div>
    ));
  }

  if (!game) {
    return <div />;
  }

  const { words, state, locations, current } = game;

  const wordObjects = words.map((word, index) => ({
    word: word,
    index: index,
    team: locations[index],
    state: state[index],
  }));

  function sortBySolved(a, b) {
    return (a.state || 0) - (b.state || 0);
  }

  const team0Words = wordObjects.filter((w) => w.team === 0).sort(sortBySolved);
  const team1Words = wordObjects.filter((w) => w.team === 1).sort(sortBySolved);
  const distractorWords = wordObjects.filter((w) => w.team === -1);
  const bombWord = wordObjects.filter((w) => w.team === -2);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          padding: "30px 10px",
        }}
      >
        {current === 0 ? (
          <span style={{ color: "blue" }}>Blue Team's Turn</span>
        ) : (
          <span style={{ color: "red" }}>Red Team's Turn</span>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: "#b2b2ff",
            flexDirection: "column",
            padding: "20px 10px",
          }}
        >
          {renderTeamWords(team0Words)}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: "#ffb2b2",
            flexDirection: "column",
            padding: "20px 10px",
          }}
        >
          {renderTeamWords(team1Words)}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          backgroundColor: "black",
          color: "white",
          flexDirection: "column",
          padding: "20px 10px",
        }}
      >
        {renderTeamWords(bombWord)}
        <br />
        Guessing this word LOSES the game!
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "lightgrey",
          flexDirection: "column",
          padding: "20px 10px",
        }}
      >
        {renderTeamWords(distractorWords)}
        <br />
        Guessing these words will end the turn.
      </div>
    </div>
  );
}
