import React from "react";

export default function WordCard(props) {
  const { selected, animation, uncovered, bgColor, color, onClick } = props;

  const boxShadowStyle = selected
    ? {
        boxShadow: "0 0 25px rgba(0, 0, 0, 1.0)",
      }
    : {};

  const boxStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid gray",
    margin: "5px",
    backgroundColor: "white",
    borderRadius: "5px",
    fontSize: "1.5em",
    fontWeight: "bold",
    touchAction: "manipulation",
  };

  const backgroundStyle = uncovered
    ? {
        backgroundColor: bgColor,
        color: color,
      }
    : {};

  return (
    <div
      onClick={onClick}
      style={{
        ...boxStyle,
        ...boxShadowStyle,
        ...backgroundStyle,
      }}
      className={selected ? `animated ${animation}` : ""}
    >
      {props.children}
    </div>
  );
}
