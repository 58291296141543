import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "react-loading-indicator";
import { Button } from "react-bootstrap";
import firebase from "firebase/app";

import "./App.css";

export default function Home() {
  let [creating, setCreating] = useState(false);
  let history = useHistory();

  async function startNewGame() {
    setCreating(true);
    const result = await firebase.functions().httpsCallable("newGame")();

    if (result.data.id) {
      setCreating(false);
      history.push(`/game/${result.data.id}`);
    } else {
      window.alert("Problem starting game! Please try again later.");
      setCreating(false);
    }
  }

  return (
    <div>
      <header className="App-header">
        <h1 className="App-title">welcome to word craft</h1>
      </header>
      <p className="App-intro">
        {!creating ? (
          <Button onClick={startNewGame}>Start New Game</Button>
        ) : (
          <LoadingIndicator />
        )}
      </p>
    </div>
  );
}
